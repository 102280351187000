@charset "utf-8";

@import "minimal-mistakes/skins/mint"; // skin
@import "minimal-mistakes"; // main partials

.site-logo img {
  max-height: 1.8rem;
}
.site-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: center;
  align-self: center;
  font-weight: bold;
  font-size: 1.7rem;
  z-index: 20;
}
h1 {
  font-weight: 500;
}

h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}
.masthead__menu {
  float: left;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  clear: both;

  .site-nav {
    margin-left: 0;

    @include breakpoint($small) {
      float: right;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    clear: both;
    list-style-type: none;
  }
}

.masthead__menu-item {
  display: block;
  list-style-type: none;
  white-space: nowrap;

  font-variant: small-caps;
  font-weight:400;

  &--lg {
    padding-right: 2em;
    font-weight: 700;
  }
}

.feature__wrapper {
    clear: both;
    margin-bottom: 2em;
    border-bottom: 0px solid #d1d0ce;
}

/*Grid Layouts  */


.grid__item {
  margin-left:2em;
  margin-right:0em;
    .archive__item-teaser {
      display: block;
      max-width: 90%;
      height: auto;

    img {
      margin: 0 auto;
      border: 7px solid #fff;
      border-radius: 50%;
  }
    @include breakpoint($small) {
      max-height: 300px;
      margin-bottom: 2em;
    }      
    @include breakpoint($medium) {
      max-height: 200px;
      max-width: 200px;
      margin-bottom: 1em;
   }
  }
}

.members-pics li {
	background:none;
	padding-left:0px;
    margin-right: 30px;
	float: left;
	margin-bottom: 45px;
}
    
.dropdown-menu {
  z-index: 25000 !important;
}

.page__content h2 {
    padding-bottom: 0.5em;
    border-bottom: none!important;
}
img[src*='#left'] {
    float: left;
    padding-right: 20px;
    padding-bottom: 5px;
}
img[src*='#right'] {
    float: right;
    padding-left: 20px;
    padding-bottom: 5px;
}
img[src*='#center'] {
    display: block;
    margin: auto;
}

//Team member stuff
.team-member {
    text-align: center;
    margin-bottom: 50px
}

.team-member img {
    margin: 0 auto;
    border: 7px solid #fff
}

.team-member h2 {
    margin-top: 20px;
    margin-bottom: 0;
    text-transform: none;
    font-size: $type-size-5;
}

.team-member p {
    margin-top: 0
}

.img-circle {
    border-radius: 50%;
}
.author__avatar img {
  display: inline-block;
  position: relative;
  width: 120px;
  height: 120px;
  object-fit: cover;
  width:110px;
  overflow:hidden;
  border-radius: 40%;
}
.img-responsive,
.thumbnail > img,
.thumbnail a > img,
.carousel-inner > .item > img,
.carousel-inner > .item > a > img {
display: block;
max-width: 100%;
height: auto;
}
.img-rounded {
border-radius: 50%;
}
.img-thumbnail {
display: inline-block;
max-width: 100%;
height: auto;
padding: 4px;
line-height: 1.42857143;
background-color: #fff;
border: 1px solid #ddd;
border-radius: 50px;
-webkit-transition: all .2s ease-in-out;
-o-transition: all .2s ease-in-out;
transition: all .2s ease-in-out;
}


// News listing - remove bullets

ul.news {
    list-style-type: none;
}

// Papers
li.paper {
    color: #666666;
}
.paper_title {
    color: #222222;
}
.paper_authors {
    color: #444444;
}

//Responsive Tables

  table {
    /*border: 1px solid #ccc;*/
    border: none;
    width: 100%;
    margin:0;
    padding:0;
    border-collapse: collapse;
    border-spacing: 0;
  }

  table tr {
    /*border: 1px solid #ddd;*/
    border: none;
    padding: 30px;
  }

  table th, table td {
    padding: 30px;
    text-align: center;
  }

  table th {
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;
  }

.card {
  border: 1px solid #ddd;
  transition: 0.3s;
  width: 100%;
  padding: 2px 16px;
  margin-top: 10px;
  margin-bottom: 10px;
}

  @media screen and (max-width: 600px) {

    table {
      border: 0;
    }

    table thead {
      display: none;
    }

    table tr {
      margin-bottom: 20px;
      display: block;
      /*border-bottom: 2px solid #ddd;*/
      border: none;
    }

    table td {
      display: block;
      text-align: right;
      font-size: 13px;
      /*border-bottom: 1px dotted #ccc;*/
      border: none;
    }

    table td:last-child {
      border-bottom: 0;
    }

    table td:before {
      content: attr(data-label);
      float: left;
      text-transform: uppercase;
      font-weight: 500;
    }
  }

//Social buttons

ul.social-buttons {
    margin-bottom: 0;
    text-align:center;
}

.list-inline {
    padding-left: 0;
    margin-left: -5px;
    list-style: none;   
}
.list-inline > li {
  display: inline-block;
  padding-right: 5px;
  padding-left: 5px;
}

ul.social-buttons li a {
    display: block;
    background-color: #222;
    height: 30px;
    width: 30px;
    border-radius: 100%;
    font-size: 16px;
    line-height: 30px;
    color: #fff;
    outline: 0;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s
}

ul.social-buttons li a:hover,
ul.social-buttons li a:focus,
ul.social-buttons li a:active {
background-color: #fed136
}
.btn:focus,
.btn:active,
.btn.active,
.btn:active:focus {
    outline: 0
}

//People layout
.row {
    margin-right: -15px;
    margin-left: -15px;
}
@media (min-width: 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left;
  }
.col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
.col-sm-offset-1 {
    margin-right: 16.66666667%;
  }
 .col-sm-offset-3 {
    margin-right: 10%;
  }
  .col-sm-4 {
    width: 33.33333333%;
  }
  .col-sm-5 {
    /*width: 41.66666667%;*/
   width: 50%;
  }
  .col-sm-2 {
    //width: 16.66666667%;
    width: 20%;
  }
}
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.text-muted {
  color: #777;
}
.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}


//dropdown menu stuff
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-timing-function: ease;
       -o-transition-timing-function: ease;
          transition-timing-function: ease;
  -webkit-transition-duration: .35s;
       -o-transition-duration: .35s;
          transition-duration: .35s;
  -webkit-transition-property: height, visibility;
       -o-transition-property: height, visibility;
          transition-property: height, visibility;
}
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.dropup,
.dropdown {
  position: relative;
}
.dropdown-toggle:focus {
  outline: 0;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
          background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
          box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
}
.dropdown-menu.pull-right {
  right: 0;
  left: auto;
}
.dropdown-menu .divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}
.dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5;
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  color: #fff;
  text-decoration: none;
  background-color: #337ab7;
  outline: 0;
}
.dropdown-menu > .disabled > a,
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
  color: #777;
}
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
  text-decoration: none;
  cursor: not-allowed;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.open > .dropdown-menu {
  display: block;
}
.open > a {
  outline: 0;
}
.dropdown-menu-right {
  right: 0;
  left: auto;
}
.dropdown-menu-left {
  right: auto;
  left: 0;
}
.dropdown-header {
  display: block;
  padding: 3px 20px;
  font-size: 12px;
  line-height: 1.42857143;
  color: #777;
  white-space: nowrap;
}
.dropdown-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 990;
}
.pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}
.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  content: "";
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
}
.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 2px;
}
@media (min-width: 768px) {
  .navbar-right .dropdown-menu {
    right: 0;
    left: auto;
  }
  .navbar-right .dropdown-menu-left {
    right: auto;
    left: 0;
  }
}
